import React from 'react'
import PropTypes from 'prop-types'

import HeaderCO from 'components/header/common'
import HeaderJA from 'components/header/ja'
import HeaderEN from 'components/header/en'
import HeaderTW from 'components/header/zh_hant'
import HeaderCN from 'components/header/zh_hans'

const Header = ({siteTitle, language, slug}) => {
    if ('ja' === language) {
        return <HeaderJA siteTitle={siteTitle} language={language} slug={slug} />
    } else if ('en' === language) {
        return <HeaderEN siteTitle={siteTitle} language={language} slug={slug}  />        
    } else if ('zh_hant' === language) {
        return <HeaderTW siteTitle={siteTitle} language={language} slug={slug}  />
    } else if ('zh_hans' === language) {
        return <HeaderCN siteTitle={siteTitle} language={language} slug={slug}  />
    } else {
        return <HeaderCO siteTitle={siteTitle} language={language} slug={slug}  />
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  language: PropTypes.string,
  slug: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header



