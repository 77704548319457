import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import { Menu } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import HotelIcon from '@material-ui/icons/Hotel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import Fab from '@material-ui/core/Fab'
import Zoom from '@material-ui/core/Zoom'

import MenuIcon from '@material-ui/icons/Menu'
import TrainIcon from '@material-ui/icons/Train'
import LanguageIcon from '@material-ui/icons/Language'
import TranslateIcon from '@material-ui/icons/Translate'
import HomeIcon from '@material-ui/icons/Home'
import ListIcon from '@material-ui/icons/ViewList'
import MoreIcon from '@material-ui/icons/MoreVert'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import logo from 'assets/logo/jruro.png'

/** Scroll to Hide AppBar */
function HideOnScroll(props) {
  const {children, window} = props
  const trigger = useScrollTrigger({target: window ? window() : undefined})

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

/** Back to Top */
function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      console.log(anchor)
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      console.log('Scroll Up')
    }
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scrollUp}>
        {children}
      </div>
    </Zoom>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  White: {
    color: '#ffffff',
  },
  scrollUp: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10000,
  },
  scrollTop: {
    color: "#ffffff",
  },
  logo: {
    marginBottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // background: 'linear-gradient(to right,  #48bd98, #48bd98)',
    background: '#48bd98',
    // background: 'theme.palette.primary.main'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  imageContainer: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const Header = ({ siteTitle, language, slug  }, {props}) => {
  const classes = useStyles()

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const mfgtTitle = {
    ja: '富士山ゴールデントライアングル',
    en: 'The Mt. Fuji Golden Triangle',
    zh_hant: '富士黃金三角',
    zh_hans: '富士黄金三角'
  }
  const mfgtLink = {
    ja: '/',
    en: '/en/',
    zh_hant: '/zh_hant/',
    zh_hans: '/zh_hans/'
  }

  /** Language Select */
  let jaURL = '';
  let enURL = '';
  let twURL = '';
  let cnURL = '';  

  if (slug) {
    jaURL = '/'+slug+'/';
    enURL = '/en/'+slug+'/';
    twURL = '/zh_hant/'+slug+'/';
    cnURL = '/zh_hans/'+slug+'/';
  } else {
    jaURL = '/';
    enURL = '/en/';
    twURL = '/zh_hant/';
    cnURL = '/zh_hans/';    
  }

  /** More Menu on AppBar */
  const [auth, setAuth] = React.useState(true)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openMenu = Boolean(anchorEl)

  const handleChange = event => {
    setAuth(event.target.checked)
  }

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  /** Isawaonsen Drawer Memu */
  const [openIsawa, setOpenIsawa] = React.useState(false);
  const handleClickIsawa = () => {
    setOpenIsawa(!openIsawa);
  }

  const data = useStaticQuery(graphql`
    {
      takeda24:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/takeda-24-generals/hero.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, jpegQuality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mfgt:file(relativePath: {eq: "campaign/mfgt/enja.png"}) {
        childImageSharp {
          fluid(maxWidth: 1000, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div id="back-to-top-anchor" className={classes.root}>
      {/* <CssBaseline /> */}
      <HideOnScroll {...props}>
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        // color="primary"
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon className={classes.White} />
          </IconButton>
          <Link to='/en/'>
            <img src={logo} alt={siteTitle} height="30" width="92" className={classes.logo} />
          </Link>
          <div className={classes.grow} />
          <div>
            {/* <IconButton
              arial-label="Display Station List"
              color="inherit"
            >
              <TrainIcon />
            </IconButton> */}
            <IconButton arial-label="Language List" arial-controls="langage-select-appbar" onClick={handleMenu} color="inherit">
              <LanguageIcon className={classes.White} />
            </IconButton>
            <Menu
              id="langage-select-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}><Link to={enURL}>ENGLISH</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to={twURL}>繁體中文</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to={cnURL}>簡体中文</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to={jaURL}>日本語</Link></MenuItem>
            </Menu>
            {/* <IconButton
              arial-label="More Menu Items"
              arial-controls="menu-more-appbar"
              arial-haspopup="true"
              onClick={handleMenu}
              edge="end"
              color="inherit"
            >
              <MoreIcon />
            </IconButton> */}
            {/* <Menu
              id="menu-more-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Menu 1</MenuItem>
              <MenuItem onClick={handleClose}>Menu 2</MenuItem>
            </Menu> */}

            
          </div>
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {/* <Typography variant="h6" color="inherit">MENU</Typography> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/">Home</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/takao/">Takao Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/otsuki/">Otsuki Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/katsunuma/">Katsunuma Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/enzan/">Enzan Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/yamanashishi/">Yamanashishi Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button onClick={handleClickIsawa}>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              Isawa-Onsen Station
            </ListItemText>
            {openIsawa ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openIsawa} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/">Overview</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/directions/">Getting To Isawa-Onsen Station</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/wine/wine-server/">Wine Tasting</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/wine/winery/#buyWine">Buy Wine</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/wine/winery/#wineryInfo">Getting to Winery</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/onsen/ashiyu/">Enjoy Foot Spa</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/fruits/">Eat Fruits</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/sightseeings/">Sightseeing</a>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a href="https://jruro.com/en/station/isawaonsen/beyond/">Starting from Isawa-Onsen Station!</a>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/kofu/">Kofu Station</a>
            </ListItemText>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <TrainIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/kobuchisawa/">kobuchisawa Station</a>
            </ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <HotelIcon />
            </ListItemIcon>
            <ListItemText>
              <a href="https://jruro.com/en/station/isawaonsen/takeda-24-generals">Lodging of Twenty-Four Samurai of Takeda Shingen</a>
            </ListItemText>
          </ListItem>
        </List>
        <div className={classes.imageContainer}>
          <a href="https://jruro.com/en/station/isawaonsen/takeda-24-generals">
            <Img objectFit="contain" fluid={data.takeda24.childImageSharp.fluid} />
          </a>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <ThumbUpIcon />
            </ListItemIcon>
            <ListItemText>
              <Link to={mfgtLink[language]}>{mfgtTitle[language]}</Link>
            </ListItemText>
          </ListItem>
        </List>
        <div className={classes.imageContainer}>
          <Link to={mfgtLink[language]}>
            <Img objectFit="contain" fluid={data.mfgt.childImageSharp.fluid} />
          </Link>
        </div>
      </Drawer>
      <ScrollTop {...props}>
        <Fab className={classes.scrollTop} color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  language: PropTypes.string,
  slug: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

export default Header
