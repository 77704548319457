import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import 'styles/layout.css';
import Header from 'components/header';
import Footer from 'components/footer';

const useStyles = makeStyles(theme => ({
    root: {
      minHeight: '100vh',
      backgroundColor: '#F5F5F5',
      backgroundColor: '#ffffff',
    },
    wrapper: {
      margin: `0 auto`,
      maxWidth: 960,
      maxWidth: 1000,
      padding: `0px 1.0875rem 1.45rem`,
      padding: `0px 0rem 1.45rem`,
      paddingTop: 48,
      // overflowX: 'hidden',
    },
    footer: {
      marginTop: '5rem',
      padding: '15px',
      backgroundColor: '#363f41',
    },
    copyright: {
      textAlign: 'center',
      color: '#ffffff',
    },
    link: {
      color: '#ffffff',
    }
}));

const Layout = ({ children, language, slug }) => {
    const classes = useStyles();
    const data = useStaticQuery(graphql`{
        site {
            siteMetadata {
                title
            }
        }
    }`);

    return (
        <div className={classes.root}>
          <Header siteTitle={data.site.siteMetadata.title} language={language} slug={slug} />
          <div className={classes.wrapper}>
            <main>{children}</main>
          </div>
          <footer className={classes.footer}>
            <div className={classes.copyright}>
              ©2019-{new Date().getFullYear()} Jruro
              {`/`}
              <a className={classes.link} href="https://zipangoods.us/">Zipangoods USA Inc.</a>
            </div>
          </footer>
        </div>
    );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string,
  slug: PropTypes.string,
}

export default Layout