import React from 'react';
import PropTypes from 'prop-types';
// import { StaticQuery, graphql } from 'gatsby';
import { Typography, Container } from '@material-ui/core';
// import Box from '@material-ui/core/Box';
import MuiLink from '@material-ui/core/Link';
import 'styles/footer.scss';

function Copyright() {
    return (
        <Typography className="copyright" component="div">
            &copy;2019-{new Date().getFullYear()} Jruro
            {'/'}
            <MuiLink color="inherit" href="https://zipangoods.us/">Zipangoods USA Inc.</MuiLink>
        </Typography>
    );
}

const Footer = ({ language, slug }) => {

    return (
        <Container id="footer" component="footer">
            <Copyright />
        </Container>
    );
}

Footer.propTypes = {
    language: PropTypes.string,
    slug: PropTypes.string,
};

export default Footer;